import axios from "axios";
import {
  TRANSACTIONS_REQUEST,
  TRANSACTIONS_SUCCESS,
  TRANSACTIONS_FAIL,
  TRANSACTIONS_ALL_REQUEST,
  TRANSACTIONS_ALL_SUCCESS,
  TRANSACTIONS_ALL_FAIL,
  TRANSACTIONS_CREATE_REQUEST,
  TRANSACTIONS_CREATE_SUCCESS,
  TRANSACTIONS_CREATE_FAIL,
  TRANSACTIONS_CREATE_RESET,
  TRANSACTIONS_UPDATE_REQUEST,
  TRANSACTIONS_UPDATE_SUCCESS,
  TRANSACTIONS_UPDATE_FAIL,
  TRANSACTIONS_UPDATE_RESET,
  TRANSACTIONS_DELETE_REQUEST,
  TRANSACTIONS_DELETE_SUCCESS,
  TRANSACTIONS_DELETE_FAIL,
  TRANSACTIONS_BY_DATE_REQUEST,
  TRANSACTIONS_BY_DATE_SUCCESS,
  TRANSACTIONS_BY_DATE_FAIL,
} from "../constants/transactionsConstants";

import BaseURL from "../../utils/api";

export const transactionList = () => async (dispatch) => {
  try {
    dispatch({
      type: TRANSACTIONS_REQUEST,
    });

    const { data } = await axios.get(`${BaseURL}/api/transactions`);

    dispatch({
      type: TRANSACTIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRANSACTIONS_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const transactionCreateAction =
  (account_id, amt, dipositor_name, tr_date) => async (dispatch) => {
    try {
      dispatch({
        type: TRANSACTIONS_CREATE_REQUEST,
      });

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      const formData = new FormData();

      const { data } = await axios.post(
        `${BaseURL}/api/transactions`,
        { account_id, amt, dipositor_name, tr_date },
        config
      );

      dispatch({
        type: TRANSACTIONS_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TRANSACTIONS_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const transactionUpdateAction =
  (id, account_id, amt, dipositor_name, tr_date) => async (dispatch) => {
    try {
      dispatch({
        type: TRANSACTIONS_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const formData = new FormData();

      const { data } = await axios.put(
        `${BaseURL}/api/transactions/${id}`,
        { id, account_id, amt, dipositor_name, tr_date },
        config
      );

      dispatch({
        type: TRANSACTIONS_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TRANSACTIONS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const transactionDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: TRANSACTIONS_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(`${BaseURL}/api/transactions/${id}`, config);

    dispatch({
      type: TRANSACTIONS_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: TRANSACTIONS_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const transactionByDateAction =
  (from_date, to_date, account_id) => async (dispatch) => {
    try {
      dispatch({
        type: TRANSACTIONS_BY_DATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      console.log(from_date, to_date, account_id);

      const { data } = await axios.post(
        `${BaseURL}/api/transactions/by_date`,
        { from_date, to_date, account_id },
        config
      );

      console.log("data", data);

      dispatch({
        type: TRANSACTIONS_BY_DATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TRANSACTIONS_BY_DATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };
