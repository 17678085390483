export const TRANSACTIONS_REQUEST = "TRANSACTIONS_REQUEST";
export const TRANSACTIONS_SUCCESS = "TRANSACTIONS_SUCCESS";
export const TRANSACTIONS_FAIL = "TRANSACTIONS_FAIL";

export const TRANSACTIONS_ALL_REQUEST = "TRANSACTIONS_ALL_REQUEST";
export const TRANSACTIONS_ALL_SUCCESS = "TRANSACTIONS_ALL_SUCCESS";
export const TRANSACTIONS_ALL_FAIL = "TRANSACTIONS_ALL_FAIL";

export const TRANSACTIONS_CREATE_REQUEST = "TRANSACTIONS_CREATE_REQUEST";
export const TRANSACTIONS_CREATE_SUCCESS = "TRANSACTIONS_CREATE_SUCCESS";
export const TRANSACTIONS_CREATE_FAIL = "TRANSACTIONS_CREATE_FAIL";
export const TRANSACTIONS_CREATE_RESET = "TRANSACTIONS_CREATE_RESET";

export const TRANSACTIONS_UPDATE_REQUEST = "TRANSACTIONS_UPDATE_REQUEST";
export const TRANSACTIONS_UPDATE_SUCCESS = "TRANSACTIONS_UPDATE_SUCCESS";
export const TRANSACTIONS_UPDATE_FAIL = "TRANSACTIONS_UPDATE_FAIL";
export const TRANSACTIONS_UPDATE_RESET = "TRANSACTIONS_UPDATE_RESET";

export const TRANSACTIONS_DELETE_REQUEST = "TRANSACTIONS_DELETE_REQUEST";
export const TRANSACTIONS_DELETE_SUCCESS = "TRANSACTIONS_DELETE_SUCCESS";
export const TRANSACTIONS_DELETE_FAIL = "TRANSACTIONS_DELETE_FAIL";

export const TRANSACTIONS_BY_DATE_REQUEST = "TRANSACTIONS_BY_DATE_REQUEST";
export const TRANSACTIONS_BY_DATE_SUCCESS = "TRANSACTIONS_BY_DATE_SUCCESS";
export const TRANSACTIONS_BY_DATE_FAIL = "TRANSACTIONS_BY_DATE_FAIL";
