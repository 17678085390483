import React, { useEffect, useState } from "react";
import { Redirect, BrowserRouter as Router } from "react-router-dom";
import axios from "axios";

//api
import API_URL from "../utils/api";

//css
import useStyles from "./styles";

//components
import Header from "./header";
import Body from "./body";

const Index = (props) => {
  var classes = useStyles();

  const [user, setUser] = useState("");

  useEffect(() => {
    const session = sessionStorage.getItem("userInfo")
      ? JSON.parse(sessionStorage.getItem("userInfo"))
      : null;

    const token = "Bearer " + session.token;

    console.log("token", token);

    fetch(`${API_URL}/api/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
    }).then((response) => {
      response.json().then((userData) => {
        console.log("Checking user Data", userData);
        setUser(userData);
      });
    });
  }, []);

  const token = sessionStorage.getItem("userInfo");

  if (!token) {
    <Redirect to="/login" />;
  }

  return (
    <div className={classes.root}>
      <Router>
        <Header user={user} />
        <main className={classes.body}>
          <Body />
        </main>
      </Router>
    </div>
  );
};

export default Index;
