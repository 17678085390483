import React, { useEffect } from "react";
import { withStyles } from "@mui/styles";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Icon,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
import {
  accountList,
  accountUpdateAction,
} from "../../redux/actions/accountsActions";
import { EditValidation } from "./FormValidation";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    height: "60px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const Dialog_Title = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Icon style={{ fontSize: 25, color: "whitesmoke" }}>shopping_bag</Icon>
      <Typography
        variant="h6"
        style={{ marginLeft: 10, fontSize: 16, fontWeight: "500", flexGrow: 1 }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "black", marginTop: "-10px" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const Dialog_Content = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(DialogContent);

const Dialog_Actions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(DialogActions);

const Popup = (props) => {
  const {
    openPopup,
    setOpenPopup,
    setNotify,
    openEditPopup,
    setOpenEditPopup,
    item,
  } = props;

  const [account_type, setAccountType] = React.useState("");
  const [account_group, setAccountGroup] = React.useState("");
  const [account_status, setStatus] = React.useState("");

  const [photo, setPhoto] = React.useState("");
  const [menu_image_preview, setMenuImagePreview] = React.useState("");
  const [sign_photo, setSignPhoto] = React.useState("");
  const [menu_image_preview2, setMenuImagePreview2] = React.useState("");
  const [id, setId] = React.useState("");

  const device_code = "123456";
  const device_validation = "Y";

  useEffect(() => {
    setId(item.id);
    setStatus(item.account_status);
    setMenuImagePreview(
      `http://dps-api.clonestudiobd.com/api/upload-images/${item.photo}`
    );
    setPhoto(item.photo);
    setMenuImagePreview2(
      `http://dps-api.clonestudiobd.com/api/upload-images/${item.sign_photo}`
    );
    setSignPhoto(item.sign_photo);
  }, [item]);

  const dispatch = useDispatch();
  const accountsUpdate = useSelector((state) => state.accountsUpdate);
  const { accountUpdates } = accountsUpdate;

  console.log("account update data", accountUpdates);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("account_name" in fieldValues)
      temp.account_name = fieldValues.account_name
        ? ""
        : "This field is required.";

    if ("account_no" in fieldValues)
      temp.account_no = fieldValues.account_no ? "" : "This field is required.";

    if ("phone_no" in fieldValues)
      temp.phone_no = fieldValues.phone_no ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    EditValidation(item, true, validate);
  console.log("oreds", values);

  const account_name = values.account_name;
  const father_name = values.father_name;
  const mother_name = values.mother_name;
  const phone_no = values.phone_no;
  const email = values.email;
  const open_date = values.open_date;
  const account_no = values.account_no;
  const address = values.address;
  const nid = values.nid;
  const nominee = values.nominee;
  const nominee_nid = values.nominee_nid;
  const op_amt = values.op_amt;
  const premium = values.premium;

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(
        accountUpdateAction(
          id,
          account_name,
          father_name,
          mother_name,
          phone_no,
          email,
          open_date,
          account_no,
          address,
          nid,
          nominee,
          nominee_nid,
          account_status,
          op_amt,
          premium,
          photo,
          sign_photo
        )
      );
      setOpenEditPopup(false);
      setTimeout(() => {
        dispatch(accountList());
      }, 2000);
      // setNotify({
      //   isOpen: true,
      //   message: 'Submitted Successfully!',
      //   type: 'success',
      // });
      resetForm();
    }
  };

  const handleClose = () => {
    setOpenEditPopup(false);
  };

  const handleUploadClick = (event) => {
    console.log(event);
    var file = event.target.files[0];
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    console.log("file", file);

    reader.onloadend = function (e) {
      setMenuImagePreview([reader.result]);
    };
    console.log(url); // Would see a path?

    setPhoto(file);
  };

  const handleUploadClick2 = (event) => {
    console.log(event);
    var file = event.target.files[0];
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    console.log("file", file);

    reader.onloadend = function (e) {
      setMenuImagePreview2([reader.result]);
    };
    console.log(url); // Would see a path?

    setSignPhoto(file);
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openEditPopup}
      onClose={handleClose}
    >
      <Dialog_Title
        style={{ color: "whitesmoke" }}
        id="customized-dialog-title"
        onClose={handleClose}
      >
        Modify Form
      </Dialog_Title>
      <Dialog_Content dividers>
        <InputForm
          setAccountType={setAccountType}
          setAccountGroup={setAccountGroup}
          account_type={account_type}
          account_group={account_group}
          setStatus={setStatus}
          setPhoto={setPhoto}
          menu_image_preview={menu_image_preview}
          setMenuImagePreview={setMenuImagePreview}
          photo={photo}
          setSignPhoto={setSignPhoto}
          menu_image_preview2={menu_image_preview2}
          setMenuImagePreview2={setMenuImagePreview2}
          sign_photo={sign_photo}
          account_status={account_status}
          item={item}
          value={values}
          handleInputChange={handleInputChange}
          handleUploadClick={handleUploadClick}
          handleUploadClick2={handleUploadClick2}
          error={errors}
          helperText={errors}
        />
      </Dialog_Content>
      <Dialog_Actions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Update
        </Button>
      </Dialog_Actions>
    </Dialog>
  );
};

export default Popup;
