import React from "react";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Slide,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import {
  accountDeleteAction,
  accountList,
} from "../../redux/actions/accountsActions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeletePopup = (props) => {
  const { openDeletePopup, setOpenDeletePopup, id, item, notify, setNotify } =
    props;

  const handleClose = () => {
    setOpenDeletePopup(false);
  };

  const dispatch = useDispatch();

  const deleteHandler = () => {
    dispatch(accountDeleteAction(id));
    setOpenDeletePopup(false);
    setTimeout(() => {
      dispatch(accountList());
    }, 2000);
  };

  return (
    <div>
      <Dialog
        open={openDeletePopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {" "}
          <p style={{ fontSize: 14 }}>
            Are you sure you want to remove{" "}
            <strong>{`${item.account_type}`}</strong>?
          </p>
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            size="small"
          >
            Cancel
          </Button>
          <Button
            onClick={deleteHandler}
            color="secondary"
            variant="contained"
            size="small"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeletePopup;
