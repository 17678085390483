import React, { useState, useEffect } from "react";

export function CreateValidation(validateOnChange = false, validate) {
  const d = new Date();
  const dv = `${d.getFullYear()}-${
    d.getMonth() > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`
  }-${d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`}`;

  const [values, setValues] = useState({
    amt: "",
    dipositor_name: "",
    tr_date: dv,
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  console.log("bbb", values);

  const resetForm = () => {
    setValues(values);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}

export function EditValidation(item, validateOnChange = false, validate) {
  const [values, setValues] = useState({
    amt: "",
    dipositor_name: "",
    tr_date: "",
  });
  const [errors, setErrors] = useState({});

  console.log("val", values);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  useEffect(() => {
    setValues({
      amt: item.amt,
      dipositor_name: item.dipositor_name,
      tr_date: item.tr_date,
    });
  }, [item]);

  console.log("bbb", values);

  const resetForm = () => {
    setValues(values);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}
