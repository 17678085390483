import React, { useState, useEffect } from "react";

import {
  Button,
  TextField,
  Grid,
  Avatar,
  Paper,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  CssBaseline,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useSelector, useDispatch } from "react-redux";
import { login } from "../redux/actions/auth";

import API_URL from "../utils/api";

import QR from "../assets/qr.png";
import Bg from "../assets/mobile.png";

const Login = ({ location, history }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("xl"));

  const [user_id, setUserId] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  console.log("api", API_URL);

  const auth = useSelector((state) => state.auth);
  console.log("userData", auth);
  const { error, userInfo } = auth;
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const redirect = location.search ? location.search.split("=")[1] : "/app";

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    } else if (error) {
      setNotify({
        isOpen: true,
        message: "Invalid User or Password!",
        type: "error",
      });
    }
  }, [history, userInfo, error, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(user_id, password));
  };

  console.log("userData", user_id, password);

  return (
    <div
      style={{
        background: `linear-gradient(to right, #0f2027, #203a43, #2c5364)`,
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          height: "80%",
          maxWidth: "400px",
        }}
      >
        <Grid container style={{ height: "100%" }}>
          <Grid container xs={12}>
            <Grid
              item
              xs={12}
              style={{
                height: "20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1 style={{}}>Sign in</h1>
            </Grid>

            <Grid
              container
              xs={12}
              style={{ height: "75%", padding: matches ? 70 : 20 }}
            >
              <Grid item xs={12} style={{ height: "15%" }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  id="email"
                  label="User Id"
                  name="user_id"
                  autoComplete="email"
                  autoFocus
                  value={user_id}
                  onChange={(e) => setUserId(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} style={{ height: "15%" }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  size="small"
                  required
                  type="password"
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  autoComplete="current-password"
                  autoFocus
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  height: "20%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={submitHandler}
                >
                  Sign In
                </Button>
              </Grid>
              <Grid
                container
                xs={12}
                style={{
                  height: "10%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  paddingLeft: 10,
                }}
              >
                <Grid item xs>
                  <FormControlLabel
                    control={
                      <Checkbox size="small" value="remember" color="primary" />
                    }
                    label={
                      <p style={{ fontSize: 14, margin: 0 }}>Remember me</p>
                    }
                  />
                </Grid>
                <Grid item></Grid>
              </Grid>
              <Grid item xs={12} style={{ height: "20%" }}></Grid>
            </Grid>

            <Grid item xs={12} style={{ height: "5%" }}>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  paddingRight: 20,
                  fontSize: 12,
                  color: "blue",
                }}
              >
                Help?
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Login;
