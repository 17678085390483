import React, { useEffect, useState } from "react";

//mui
import { makeStyles } from "@mui/styles";
import { Icon } from "@mui/material";

//components
import Table from "./Table";

//redux
import { useDispatch, useSelector } from "react-redux";

import { accountList } from "../../redux/actions/accountsActions";
import { transactionByDateAction } from "../../redux/actions/transactionsActions";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Body = (props) => {
  const dispatch = useDispatch();
  const { appUser } = props;

  const transactionByDate = useSelector((state) => state.transactionByDate);
  const { transactionsByDates } = transactionByDate;

  const account = useSelector((state) => state.account);
  const { accounts } = account;

  console.log(accounts.data);

  const d = new Date();
  const dv = `${d.getFullYear()}-${
    d.getMonth() > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`
  }-${d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`}`;

  console.log(appUser);

  useEffect(() => {
    dispatch(accountList());
  }, [dispatch, appUser]);

  let arr = Object.keys(transactionsByDates).map((k) => transactionsByDates[k]);

  arr
    .sort((a, b) => b.id - a.id)
    .forEach((item, index) => (item.id = index + 1));

  console.log(arr);

  const classes = useStyles();

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 20,
        }}
      >
        <Table transactionsByDates={arr} accounts={accounts.data} />
      </div>
    </div>
  );
};

export default Body;
