import React, { useReducer } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import {
  Box,
  TextField,
  InputAdornment,
  Grid,
  Icon,
  InputLabel,
  NativeSelect,
} from "@mui/material";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles, withStyles, useTheme } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import TableFooter from "@mui/material/TableFooter";
import {
  FormLabel,
  Radio,
  RadioGroup,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { transactionByDateAction } from "../../redux/actions/transactionsActions";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { Link } from "react-router-dom";
import { IndeterminateCheckBox } from "@mui/icons-material";
import axios from "axios";

//components
// import Popup from "./Popup";
// import EditPopup from "./EditPopup";
// import DeletePopup from "./DeletePopup";

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Honeycomb", 408, 3.2, 87, 6.5),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Jelly Bean", 375, 0.0, 94, 0.0),
  createData("KitKat", 518, 26.0, 65, 7.0),
  createData("Lollipop", 392, 0.2, 98, 0.0),
  createData("Marshmallow", 318, 0, 81, 2.0),
  createData("Nougat", 360, 19.0, 9, 37.0),
  createData("Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly

function stableSort(array, comparator, searchValues) {
  const stabilizedThis = searchValues.fn(array).map((el, id) => [el, id]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "account_name",
    numeric: false,
    disablePadding: false,
    label: "Account Name",
  },
  {
    id: "account_no",
    numeric: false,
    disablePadding: false,
    label: "Account No.",
  },
  {
    id: "phone_no",
    numeric: false,
    disablePadding: false,
    label: "Phone No",
  },
  {
    id: "tr_date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "RECEIVE_AMT",
    numeric: false,
    disablePadding: false,
    label: "Receive Amount",
  },

  {
    id: "Bal_amt",
    numeric: false,
    disablePadding: false,
    label: "Balance",
  },
  // { id: 8, numeric: false, disablePadding: false, label: "Actions" },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
            size="small"
          />
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ top: 57, minWidth: headCell.minWidth }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {/* {order === "desc" ? "sorted descending" : "sorted ascending"} */}
                  {order === "desc" ? "" : ""}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, handleSearch } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <div style={{ flexGrow: 1 }}>
          {/* <TextField
            label="Search"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          /> */}
        </div>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

// EnhancedTableToolbar.propTypes = {
//   numSelected: PropTypes.number.isRequired,
// };

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  root: {
    width: "80%",
  },
  table: {
    minWidth: 750,
  },
  tableCell: {
    fontSize: "0.1em",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flex: "1 1 100%",
  },
  pagination: {
    "& .MuiTablePagination-spacer": {},
  },
});

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

export default function EnhancedTable(props) {
  const dispatch = useDispatch();
  const { transactionsByDates, permissions, accounts } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [account_id, setAccountId] = React.useState(null);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [searchValues, setSearchValues] = React.useState({
    fn: (transactionsByDates) => {
      return transactionsByDates;
    },
  });

  const [smsData, setSMSData] = React.useState();

  const d = new Date();
  const dvToday = `${d.getFullYear()}-${
    d.getMonth() > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`
  }-${d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`}`;

  var date = new Date();
  date.setDate(date.getDate() - 10);

  const finalDate = `${date.getFullYear()}-${
    date.getMonth() > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
  }-${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`}`;

  console.log("finalDate", finalDate);
  console.log("dvToday", dvToday);

  const [item, setItem] = React.useState("");
  const [id, setId] = React.useState("");

  //
  const [openPopup, setOpenPopup] = React.useState(false);
  const [openEditPopup, setOpenEditPopup] = React.useState(false);
  const [openDeletePopup, setOpenDeletePopup] = React.useState(false);

  const initialState = { selectedOptions: null };

  function reducer(state, action) {
    switch (action.type) {
      case "SET_SELECTED_OPTIONS":
        return { selectedOptions: action.payload.options };
      case "REMOVE_OPTION":
        return {
          selectedOptions: state.selectedOptions.filter(
            (option) => option.id !== action.payload.id
          ),
        };
      case "RESET":
        return initialState;
      default:
        throw new Error();
    }
  }

  const [state, dispatch2] = useReducer(reducer, initialState);

  const handleChange2 = (event, values) => {
    dispatch2({ type: "SET_SELECTED_OPTIONS", payload: { options: values } });
  };

  const [formData, setFormData] = React.useState({
    from: finalDate,
    to: dvToday,
  });
  //
  const classes = useStyles();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = transactionsByDates.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const total = () => {
    let value = 0;
    transactionsByDates.map((item) => {
      value = value + parseFloat(item.amount);
    });
    return value;
  };

  const handleSearch = (e) => {
    let target = e.target;
    setSearchValues({
      fn: (transactionsByDates) => {
        if (target.value === "") return transactionsByDates;
        else
          return transactionsByDates.filter(
            (x) =>
              (x.transaction_date
                ? x.transaction_date
                    .toString()
                    .toLowerCase()
                    .includes(target.value)
                : null) ||
              (x.transaction_no
                ? x.transaction_no
                    .toString()
                    .toLowerCase()
                    .includes(target.value)
                : null) ||
              (x.account_name
                ? x.account_name.toString().toLowerCase().includes(target.value)
                : null)
          );
      },
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // const formChangeHandler = (parameter, value) => {
  //   let from = "from" in formData ? formData.from : "";
  //   let to = "to" in formData ? formData.to : "";

  //   if (parameter === "from") {
  //     from = value;
  //   }
  //   if (parameter === "to") {
  //     to = value;
  //   }

  //   if (!(from === "" || to === "" || account_id === null)) {

  //     console.log(from, to, account_id);
  //   }
  // };

  React.useEffect(() => {
    if (state.selectedOptions !== null) {
      setAccountId(state.selectedOptions.id);
    }
  }, [state.selectedOptions]);

  console.log("account_id", account_id);

  React.useEffect(() => {
    if (account_id && formData.from) {
      dispatch(transactionByDateAction(formData.from, formData.to, account_id));
    } else {
      dispatch(transactionByDateAction(formData.from, formData.to, ""));
    }
  }, [account_id, formData.from]);

  console.log(selected);

  React.useEffect(() => {
    if (selected) {
      const transactionsByDatesFilter = transactionsByDates
        .filter((item) => {
          return selected.includes(item.id);
        })
        .map((item) => ({
          numbers: [`${item.phone_no}`],
          Bal_amt: item.Bal_amt,
          amt: item.RECEIVE_AMT,
          tr_date: item.tr_date,
        }));

      setSMSData(transactionsByDatesFilter);
    }
  }, [selected]);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{}}>
        <Link
          to="/app"
          color="inherit"
          style={{
            textDecoration: "none",
            color: "white",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Icon style={{ fontSize: 20, marginTop: 25, color: "#13292A" }}>
            arrow_back
          </Icon>
          <h4 style={{ marginLeft: 5, color: "#13292A" }}>Back</h4>
        </Link>
      </div>
      <div style={{ display: "flex" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} sm={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Icon style={{ fontSize: 25, marginTop: 25, color: "#13292A" }}>
                assessment
              </Icon>
              <h2 style={{ marginLeft: 5, color: "#13292A" }}>Reports</h2>
            </div>
          </Grid>
        </Grid>
      </div>

      <div style={{ display: "flex", marginTop: "10px", padding: "5px" }}>
        <Grid container spacing={3}>
          <Grid item sm={4}>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="date"
              label="From"
              type="date"
              value={formData.from}
              className={classes.textField}
              onChange={(e) => {
                setFormData({ ...formData, from: e.target.value });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="date"
              label="To"
              type="date"
              value={formData.to}
              className={classes.textField}
              onChange={(e) => {
                setFormData({ ...formData, to: e.target.value });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <Autocomplete
              fullWidth={true}
              size="small"
              options={accounts}
              getOptionLabel={(option) =>
                `${option.account_name} (${option.account_no})`
              }
              value={state.selectedOptions}
              getOptionSelected={(option, value) =>
                option.department_name === value.department_name
              }
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.account_name} ({option.account_no})
                </Box>
              )}
              style={{ width: "100%" }}
              onChange={handleChange2}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Choose Account Name & No."
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={4} md={4} sm={4}>
            <div style={{ marginBottom: 25 }}>
              <div style={{ flexGrow: 1, width: "100%" }}>
                <TextField
                  label="Search"
                  id="outlined-size-small"
                  variant="outlined"
                  fullWidth
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleSearch}
                />
              </div>
            </div>
          </Grid> */}
        </Grid>
      </div>

      <Paper sx={{ width: "100%", mb: 2 }}>
        {/* <EnhancedTableToolbar
          numSelected={selected.length}
          handleSearch={handleSearch}
            // printPermission={printPermission}
        /> */}
        <TableContainer>
          <Table
            sx={{ minWidth: "90vw" }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={transactionsByDates ? transactionsByDates.length : null}
            />
            <TableBody>
              {stableSort(
                transactionsByDates,
                getComparator(order, orderBy),
                searchValues
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, id) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${id}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell
                        onClick={(event) => handleClick(event, row.id)}
                        padding="checkbox"
                        style={{ width: "10%" }}
                      >
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          size="small"
                        />
                      </TableCell>
                      <TableCell
                        id={labelId}
                        align="left"
                        // scope="row"
                        // padding="checkbox"
                        style={{ fontSize: 12, width: "15%" }}
                      >
                        {row.account_name}
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{ fontSize: 12, width: "25%" }}
                        // padding="checkbox"
                      >
                        {row.account_no}
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{ fontSize: 12, width: "25%" }}
                        // padding="checkbox"
                      >
                        {row.phone_no}
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{ fontSize: 12, width: "10%" }}
                        // padding="checkbox"
                      >
                        {row.tr_date}
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{ fontSize: 12, width: "10%" }}
                        // padding="checkbox"
                      >
                        {row.RECEIVE_AMT}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ fontSize: 12, width: "10%" }}
                        // padding="checkbox"
                      >
                        {row.Bal_amt}
                      </TableCell>
                    </TableRow>
                  );
                })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 25 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <div>
          <Grid container>
            <Grid
              item
              xs={5}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                height: 30,
                marginTop: "1%",
              }}
            >
              {selected.length ? (
                <div style={{ marginLeft: 50 }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{ height: 34 }}
                    onClick={() => {
                      return axios
                        .post(
                          `http://dps-api.clonestudiobd.com/api/send_sms/bulk`,
                          {
                            data: smsData,
                          },
                          config
                        )
                        .then((response) => {
                          if (response.status === 200) {
                            alert("Sms send successful.");
                            setSelected([]);
                          }
                        })
                        .catch((error) => {
                          console.log({ ...error });
                        });
                    }}
                  >
                    Send SMS
                  </Button>
                </div>
              ) : null}
            </Grid>
            <Grid item xs={7}>
              <TablePagination
                rowsPerPageOptions={[15, 20, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={transactionsByDates.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                style={{
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              />
            </Grid>
          </Grid>
        </div>
      </Paper>
      {/* <Popup setOpenPopup={setOpenPopup} openPopup={openPopup} />
      <EditPopup
        setOpenEditPopup={setOpenEditPopup}
        openEditPopup={openEditPopup}
        item={item}
      />
      <DeletePopup
        setOpenDeletePopup={setOpenDeletePopup}
        openDeletePopup={openDeletePopup}
        id={id}
        item={item}
      /> */}
    </Box>
  );
}
