import React, { useState, useEffect } from "react";

export function CreateValidation(validateOnChange = false, validate) {
  const [values, setValues] = useState({
    account_name: "",
    father_name: "",
    mother_name: "",
    phone_no: "",
    email: "",
    open_date: "",
    account_no: "",
    address: "",
    nid: "",
    nominee: "",
    nominee_nid: "",
    account_status: "",
    op_amt: "",
    premium: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  console.log("bbb", values);

  const resetForm = () => {
    setValues({
      account_name: "",
      father_name: "",
      mother_name: "",
      phone_no: "",
      email: "",
      open_date: "",
      account_no: "",
      address: "",
      nid: "",
      nominee: "",
      nominee_nid: "",
      account_status: "",
      op_amt: "",
      premium: "",
    });
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}

export function EditValidation(item, validateOnChange = false, validate) {
  const [values, setValues] = useState({
    account_name: "",
    father_name: "",
    mother_name: "",
    phone_no: "",
    email: "",
    open_date: "",
    account_no: "",
    address: "",
    nid: "",
    nominee: "",
    nominee_nid: "",
    account_status: "",
    op_amt: "",
    premium: "",
  });
  const [errors, setErrors] = useState({});

  console.log("val", values);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  useEffect(() => {
    setValues({
      account_name: item.account_name,
      father_name: item.father_name,
      mother_name: item.mother_name,
      phone_no: item.phone_no,
      email: item.email,
      open_date: item.open_date,
      account_no: item.account_no,
      address: item.address,
      nid: item.nid,
      nominee: item.nominee,
      nominee_nid: item.nominee_nid,
      account_status: item.account_status,
      op_amt: item.op_amt,
      premium: item.premium,
    });
  }, [item]);

  console.log("bbb", values);

  const resetForm = () => {
    setValues(values);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}
