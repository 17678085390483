import React, { useReducer } from "react";

import {
  Grid,
  Typography,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  FormControl,
  InputLabel,
  FormControlLabel,
  NativeSelect,
  Select,
  MenuItem,
  Box,
  Checkbox,
  Chip,
} from "@mui/material";

import Autocomplete from "@mui/material/Autocomplete";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 10,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  textField: {
    "&:hover": {
      backgroundColor: "whitesmoke",
    },
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    value,
    handleInputChange,
    error,
    helperText,
    setAccountType,
    setAccountGroup,
    account_type,
    account_group,
    setStatus,
    status,
    item,
    accounts,
    account_id,
    setAccountId,
    handleChange1,
    submitted,
    checked,
  } = props;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const accountGroupSelect = (type) => {
    if (type === "owners capital") {
      setAccountGroup("A");
    } else if (type === "bank loan") {
      setAccountGroup("L");
    } else if (type === "supplier") {
      setAccountGroup("L");
    } else if (type === "customer") {
      setAccountGroup("A");
    } else if (type === "fixed asset") {
      setAccountGroup("A");
    } else if (type === "cash") {
      setAccountGroup("A");
    } else if (type === "bank") {
      setAccountGroup("A");
    } else if (type === "sales") {
      setAccountGroup("I");
    } else if (type === "purchase") {
      setAccountGroup("E");
    } else {
      setAccountGroup("E");
    }
  };

  console.log("edit data", item);

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  var accountName =
    accounts && item
      ? accounts
          .filter((item2) => {
            return item2.id === item.account_id;
          })
          .map((item) => `${item.account_name}  (${item.account_no})`)
      : [];

  console.log("accountName", accountName);

  const initialState = { selectedOptions: null };

  function reducer(state, action) {
    switch (action.type) {
      case "SET_SELECTED_OPTIONS":
        return { selectedOptions: action.payload.options };
      case "REMOVE_OPTION":
        return {
          selectedOptions: state.selectedOptions.filter(
            (option) => option.id !== action.payload.id
          ),
        };
      case "RESET":
        return initialState;
      default:
        throw new Error();
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleChange2 = (event, values) => {
    dispatch({ type: "SET_SELECTED_OPTIONS", payload: { options: values } });
  };

  const removeOption = (id) => {
    dispatch({ type: "REMOVE_OPTION", payload: { id: id } });
  };

  console.log(state.selectedOptions);

  React.useEffect(() => {
    if (state.selectedOptions !== null) {
      setAccountId(state.selectedOptions.id);
    }
  }, [state.selectedOptions]);

  React.useEffect(() => {
    if (submitted === true) dispatch({ type: "RESET" });
  }, [submitted]);

  var accountName =
    accounts && item
      ? accounts
          .filter((item2) => {
            return item2.id === item.account_id;
          })
          .map((item) => `${item.account_name}  (${item.account_no})`)
      : "";

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {item ? (
              <TextField
                name="amt"
                variant="outlined"
                fullWidth
                id="amt"
                label="Account Name & No."
                autoFocus
                size="small"
                className={classes.textField}
                value={accountName}
                error={error.amt}
                helperText={helperText.amt}
                onChange={handleInputChange}
                InputLabelProps={{ style: { fontSize: 13 } }}
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                fullWidth={true}
                size="medium"
                options={accounts}
                getOptionLabel={(option) =>
                  `${option.account_name} (${option.account_no})`
                }
                value={state.selectedOptions}
                getOptionSelected={(option, value) =>
                  option.department_name === value.department_name
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.account_name} ({option.account_no})
                  </Box>
                )}
                style={{ width: "100%" }}
                onChange={handleChange2}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Choose Account Name & No."
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="tr_date"
              variant="outlined"
              required
              fullWidth
              id="tr_date"
              label="Transaction Date"
              type="date"
              autoFocus
              size="small"
              value={value.tr_date}
              error={error.tr_date}
              helperText={helperText.tr_date}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="amt"
              variant="outlined"
              fullWidth
              required
              id="amt"
              label="Amount"
              autoFocus
              size="small"
              className={classes.textField}
              value={value.amt}
              error={error.amt}
              helperText={helperText.amt}
              onChange={handleInputChange}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="dipositor_name"
              variant="outlined"
              fullWidth
              id="dipositor_name"
              label="Depositor Name"
              autoFocus
              size="small"
              className={classes.textField}
              value={value.dipositor_name}
              error={error.dipositor_name}
              helperText={helperText.dipositor_name}
              onChange={handleInputChange}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
          </Grid>
          <Grid item xs>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  size="small"
                  color="primary"
                  onChange={handleChange1}
                />
              }
              label={<p style={{ fontSize: 14, margin: 0 }}>Send SMS</p>}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
