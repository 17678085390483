export const ACCOUNT_REQUEST = "ACCOUNT_REQUEST";
export const ACCOUNT_SUCCESS = "ACCOUNT_SUCCESS";
export const ACCOUNT_FAIL = "ACCOUNT_FAIL";

export const ACCOUNT_ALL_REQUEST = "ACCOUNT_ALL_REQUEST";
export const ACCOUNT_ALL_SUCCESS = "ACCOUNT_ALL_SUCCESS";
export const ACCOUNT_ALL_FAIL = "ACCOUNT_ALL_FAIL";

export const ACCOUNT_CREATE_REQUEST = "ACCOUNT_CREATE_REQUEST";
export const ACCOUNT_CREATE_SUCCESS = "ACCOUNT_CREATE_SUCCESS";
export const ACCOUNT_CREATE_FAIL = "ACCOUNT_CREATE_FAIL";
export const ACCOUNT_CREATE_RESET = "ACCOUNT_CREATE_RESET";

export const ACCOUNT_UPDATE_REQUEST = "ACCOUNT_UPDATE_REQUEST";
export const ACCOUNT_UPDATE_SUCCESS = "ACCOUNT_UPDATE_SUCCESS";
export const ACCOUNT_UPDATE_FAIL = "ACCOUNT_UPDATE_FAIL";
export const ACCOUNT_UPDATE_RESET = "ACCOUNT_UPDATE_RESET";

export const ACCOUNT_DELETE_REQUEST = "ACCOUNT_DELETE_REQUEST";
export const ACCOUNT_DELETE_SUCCESS = "ACCOUNT_DELETE_SUCCESS";
export const ACCOUNT_DELETE_FAIL = "ACCOUNT_DELETE_FAIL";
