import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Background from "../assets/bank-account.png";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

export default function ResponsiveGrid() {
  return (
    <Box sx={{ flexGrow: 1, marginTop: 10 }}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={12} sm={4} md={4}>
          <Link underline="none" component={RouterLink} to="/app/accounts">
            <Card
              sx={{
                minWidth: 275,
                minHeight: 300,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              style={{ backgroundImage: `url(${Background})` }}
            >
              <CardContent>
                <Typography
                  sx={{ fontSize: 34 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Member List
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Link underline="none" component={RouterLink} to="/app/transactions">
            <Card
              sx={{
                minWidth: 275,
                minHeight: 300,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              style={{ backgroundImage: `url(${Background})` }}
            >
              <CardContent>
                <Typography
                  sx={{ fontSize: 26 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Transactions/Deposit List
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Link underline="none" component={RouterLink} to="/app/reports">
            <Card
              sx={{
                minWidth: 275,
                minHeight: 300,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              style={{ backgroundImage: `url(${Background})` }}
            >
              <CardContent>
                <Typography
                  sx={{ fontSize: 34 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Reports
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}
