import axios from "axios";
import {
  ACCOUNT_REQUEST,
  ACCOUNT_SUCCESS,
  ACCOUNT_FAIL,
  ACCOUNT_ALL_REQUEST,
  ACCOUNT_ALL_SUCCESS,
  ACCOUNT_ALL_FAIL,
  ACCOUNT_CREATE_REQUEST,
  ACCOUNT_CREATE_SUCCESS,
  ACCOUNT_CREATE_FAIL,
  ACCOUNT_CREATE_RESET,
  ACCOUNT_UPDATE_REQUEST,
  ACCOUNT_UPDATE_SUCCESS,
  ACCOUNT_UPDATE_FAIL,
  ACCOUNT_UPDATE_RESET,
  ACCOUNT_DELETE_REQUEST,
  ACCOUNT_DELETE_SUCCESS,
  ACCOUNT_DELETE_FAIL,
} from "../constants/accountsConstants";

import BaseURL from "../../utils/api";

export const accountList = () => async (dispatch) => {
  try {
    dispatch({
      type: ACCOUNT_REQUEST,
    });

    const { data } = await axios.get(`${BaseURL}/api/accounts`);

    dispatch({
      type: ACCOUNT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ACCOUNT_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const accountCreateAction =
  (
    account_name,
    father_name,
    mother_name,
    phone_no,
    email,
    open_date,
    account_no,
    address,
    nid,
    nominee,
    nominee_nid,
    account_status,
    op_amt,
    premium,
    photo,
    sign_photo
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACCOUNT_CREATE_REQUEST,
      });

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      const formData = new FormData();
      formData.append("account_name", account_name);
      formData.append("father_name", father_name);
      formData.append("mother_name", mother_name);
      formData.append("phone_no", phone_no);
      formData.append("email", email);
      formData.append("open_date", open_date);
      formData.append("account_no", account_no);
      formData.append("address", address);
      formData.append("nid", nid);
      formData.append("nominee", nominee);
      formData.append("nominee_nid", nominee_nid);
      formData.append("account_status", account_status);
      formData.append("op_amt", op_amt);
      formData.append("premium", premium);
      formData.append("photo", photo);
      formData.append("sign_photo", sign_photo);

      const { data } = await axios.post(
        `${BaseURL}/api/accounts`,
        formData,
        config
      );

      dispatch({
        type: ACCOUNT_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ACCOUNT_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const accountUpdateAction =
  (
    id,
    account_name,
    father_name,
    mother_name,
    phone_no,
    email,
    open_date,
    account_no,
    address,
    nid,
    nominee,
    nominee_nid,
    account_status,
    op_amt,
    premium,
    photo,
    sign_photo
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACCOUNT_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const formData = new FormData();
      formData.append("account_name", account_name);
      formData.append("father_name", father_name);
      formData.append("mother_name", mother_name);
      formData.append("phone_no", phone_no);
      formData.append("email", email);
      formData.append("open_date", open_date);
      formData.append("account_no", account_no);
      formData.append("address", address);
      formData.append("nid", nid);
      formData.append("nominee", nominee);
      formData.append("nominee_nid", nominee_nid);
      formData.append("account_status", account_status);
      formData.append("op_amt", op_amt);
      formData.append("premium", premium);
      formData.append("photo", photo);
      formData.append("sign_photo", sign_photo);

      const { data } = await axios.put(
        `${BaseURL}/api/accounts/${id}`,
        formData,
        config
      );

      dispatch({
        type: ACCOUNT_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ACCOUNT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const accountDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ACCOUNT_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(`${BaseURL}/api/accounts/${id}`, config);

    dispatch({
      type: ACCOUNT_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: ACCOUNT_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
