import {
  TRANSACTIONS_REQUEST,
  TRANSACTIONS_SUCCESS,
  TRANSACTIONS_FAIL,
  TRANSACTIONS_ALL_REQUEST,
  TRANSACTIONS_ALL_SUCCESS,
  TRANSACTIONS_ALL_FAIL,
  TRANSACTIONS_CREATE_REQUEST,
  TRANSACTIONS_CREATE_SUCCESS,
  TRANSACTIONS_CREATE_FAIL,
  TRANSACTIONS_CREATE_RESET,
  TRANSACTIONS_UPDATE_REQUEST,
  TRANSACTIONS_UPDATE_SUCCESS,
  TRANSACTIONS_UPDATE_FAIL,
  TRANSACTIONS_UPDATE_RESET,
  TRANSACTIONS_DELETE_REQUEST,
  TRANSACTIONS_DELETE_SUCCESS,
  TRANSACTIONS_DELETE_FAIL,
  TRANSACTIONS_BY_DATE_REQUEST,
  TRANSACTIONS_BY_DATE_SUCCESS,
  TRANSACTIONS_BY_DATE_FAIL,
} from "../constants/transactionsConstants";

export const transactionsListReducer = (
  state = { transactions: [] },
  action
) => {
  switch (action.type) {
    case TRANSACTIONS_REQUEST:
      return { loading: true, transactions: [] };
    case TRANSACTIONS_SUCCESS:
      return { loading: false, transactions: action.payload };
    case TRANSACTIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const transactionsCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TRANSACTIONS_CREATE_REQUEST:
      return { loading: true };
    case TRANSACTIONS_CREATE_SUCCESS:
      return { loading: false, transactionCreate: action.payload };
    case TRANSACTIONS_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case TRANSACTIONS_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const transactionsUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case TRANSACTIONS_UPDATE_REQUEST:
      return { loading: true };
    case TRANSACTIONS_UPDATE_SUCCESS:
      return { loading: false, transactionUpdate: action.payload };
    case TRANSACTIONS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case TRANSACTIONS_UPDATE_RESET:
      return { transactionCreate: {} };
    default:
      return state;
  }
};

export const transactionsDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TRANSACTIONS_DELETE_REQUEST:
      return { loading: true };
    case TRANSACTIONS_DELETE_SUCCESS:
      return { loading: false, success: true };
    case TRANSACTIONS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const transactionsByDateReducer = (
  state = { transactionsByDates: [] },
  action
) => {
  switch (action.type) {
    case TRANSACTIONS_BY_DATE_REQUEST:
      return { loading: true, transactionsByDates: [] };
    case TRANSACTIONS_BY_DATE_SUCCESS:
      return { loading: false, transactionsByDates: action.payload };
    case TRANSACTIONS_BY_DATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
