import React, { useEffect, useState } from "react";

//mui
import { makeStyles } from "@mui/styles";
import { Icon } from "@mui/material";

//components
import Table from "./Table";

//redux
import { useDispatch, useSelector } from "react-redux";
import { transactionList } from "../../redux/actions/transactionsActions";
import { accountList } from "../../redux/actions/accountsActions";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Body = (props) => {
  const dispatch = useDispatch();
  const [app_menu_id, setAppMenuId] = useState(27);

  const transaction = useSelector((state) => state.transaction);
  const { transactions } = transaction;

  const account = useSelector((state) => state.account);
  const { accounts } = account;

  console.log(transactions.data);

  useEffect(() => {
    dispatch(transactionList());
    dispatch(accountList());
  }, [dispatch]);

  const classes = useStyles();

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 20,
        }}
      >
        {!transactions.data ? (
          <div>Loadnig</div>
        ) : (
          <Table transactions={transactions.data} accounts={accounts.data} />
        )}
      </div>
    </div>
  );
};

export default Body;
