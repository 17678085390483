import { combineReducers } from "redux";

import { loginReducer } from "./auth";

import {
  userListReducer,
  userCreateReducer,
  userUpdateReducer,
  userDeleteReducer,
} from "./user";

import {
  accountsListReducer,
  accountsCreateReducer,
  accountsUpdateReducer,
  accountsDeleteReducer,
} from "./accountsReducers";

import {
  transactionsListReducer,
  transactionsCreateReducer,
  transactionsUpdateReducer,
  transactionsDeleteReducer,
  transactionsByDateReducer,
} from "./transactionsReducers";

const rootReducer = combineReducers({
  auth: loginReducer,
  //users
  user: userListReducer,
  userCreate: userCreateReducer,
  userUpdate: userUpdateReducer,
  userDelete: userDeleteReducer,
  //accounts
  account: accountsListReducer,
  accountsCreate: accountsCreateReducer,
  accountsUpdate: accountsUpdateReducer,
  accountsDelete: accountsDeleteReducer,
  //transactions
  transaction: transactionsListReducer,
  transactionsCreate: transactionsCreateReducer,
  transactionsUpdate: transactionsUpdateReducer,
  transactionsDelete: transactionsDeleteReducer,
  transactionByDate: transactionsByDateReducer,
});

export default rootReducer;
