import {
  ACCOUNT_REQUEST,
  ACCOUNT_SUCCESS,
  ACCOUNT_FAIL,
  ACCOUNT_ALL_REQUEST,
  ACCOUNT_ALL_SUCCESS,
  ACCOUNT_ALL_FAIL,
  ACCOUNT_CREATE_REQUEST,
  ACCOUNT_CREATE_SUCCESS,
  ACCOUNT_CREATE_FAIL,
  ACCOUNT_CREATE_RESET,
  ACCOUNT_UPDATE_REQUEST,
  ACCOUNT_UPDATE_SUCCESS,
  ACCOUNT_UPDATE_FAIL,
  ACCOUNT_UPDATE_RESET,
  ACCOUNT_DELETE_REQUEST,
  ACCOUNT_DELETE_SUCCESS,
  ACCOUNT_DELETE_FAIL,
} from "../constants/accountsConstants";

export const accountsListReducer = (state = { accounts: [] }, action) => {
  switch (action.type) {
    case ACCOUNT_REQUEST:
      return { loading: true, accounts: [] };
    case ACCOUNT_SUCCESS:
      return { loading: false, accounts: action.payload };
    case ACCOUNT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const accountsCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCOUNT_CREATE_REQUEST:
      return { loading: true };
    case ACCOUNT_CREATE_SUCCESS:
      return { loading: false, accountCreate: action.payload };
    case ACCOUNT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ACCOUNT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const accountsUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCOUNT_UPDATE_REQUEST:
      return { loading: true };
    case ACCOUNT_UPDATE_SUCCESS:
      return { loading: false, accountUpdate: action.payload };
    case ACCOUNT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ACCOUNT_UPDATE_RESET:
      return { accountCreate: {} };
    default:
      return state;
  }
};

export const accountsDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCOUNT_DELETE_REQUEST:
      return { loading: true };
    case ACCOUNT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ACCOUNT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
