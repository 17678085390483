import React from "react";
import { withStyles } from "@mui/styles";
import axios from "axios";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Icon,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
import { CreateValidation } from "./FormValidation";
import {
  transactionList,
  transactionCreateAction,
} from "../../redux/actions/transactionsActions";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    height: "60px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const Dialog_Title = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Icon style={{ fontSize: 25, color: "whitesmoke" }}>shopping_bag</Icon>
      <Typography
        variant="h5"
        style={{ marginLeft: 10, fontSize: 16, fontWeight: "500", flexGrow: 1 }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "black" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const Dialog_Content = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(DialogContent);

const Dialog_Actions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(DialogActions);

const Popup = (props) => {
  const { openPopup, setOpenPopup, setNotify, accounts } = props;
  const [account_type, setAccountType] = React.useState("");
  const [account_group, setAccountGroup] = React.useState("");
  const [account_status, setStatus] = React.useState("Y");

  const [photo, setPhoto] = React.useState("");
  const [menu_image_preview, setMenuImagePreview] = React.useState("");

  const [sign_photo, setSignPhoto] = React.useState("");
  const [menu_image_preview2, setMenuImagePreview2] = React.useState("");

  const [account_id, setAccountId] = React.useState(null);

  const [checked, setChecked] = React.useState(true);

  const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const [submitted, setSubmitted] = React.useState(false);

  const device_code = "123456";
  const device_validation = "Y";

  const dispatch = useDispatch();
  const accountsCreate = useSelector((state) => state.accountsCreate);
  const { accountCreates } = accountsCreate;
  console.log("accountData", accountCreates);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("tr_date" in fieldValues)
      temp.tr_date = fieldValues.tr_date ? "" : "This field is required.";

    if ("amt" in fieldValues)
      temp.amt = fieldValues.amt ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    CreateValidation(true, validate);

  const amt = values.amt;
  const dipositor_name = values.dipositor_name;
  const tr_date = values.tr_date;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const d = new Date();
  const dv = `${d.getFullYear()}-${
    d.getMonth() > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`
  }-${d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`}`;

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      if (checked === true && account_id) {
        const number = accounts
          ? accounts
              .filter((item) => {
                return item.id === account_id;
              })
              .map((item) => item.phone_no)
          : "";

        axios
          .post(
            `http://dps-api.clonestudiobd.com/api/transactions`,
            { account_id, amt, dipositor_name, tr_date },
            config
          )
          .then((response) => {
            if (response.status === 201) {
              axios
                .post(
                  `http://dps-api.clonestudiobd.com/api/transactions/by_date`,
                  {
                    from_date: dv,
                    to_date: dv,
                    account_id: account_id,
                  },
                  config
                )
                .then((response) => {
                  if (response.status === 200) {
                    const Bal_amt = response.data[0].Bal_amt;

                    axios
                      .post(
                        `http://dps-api.clonestudiobd.com/api/send_sms`,
                        {
                          numbers: [`${number}`],
                          Bal_amt: Bal_amt,
                          amt: amt,
                          tr_date: tr_date,
                        },
                        config
                      )
                      .then((response) => {
                        if (response.status === 200) {
                          setSubmitted(true);
                          alert("Sms send successful.");
                        }
                      })
                      .catch((error) => {
                        console.log({ ...error });
                      });
                  }
                });
            }
          });

        // axios
        //   .post(
        //     `http://dps-api.clonestudiobd.com/api/send_sms`,
        //     { numbers: [`${number}`] },
        //     config
        //   )
        //   .then((response) => {
        //     if (response.status === 200) {
        //       dispatch(
        //         transactionCreateAction(
        //           account_id,
        //           amt,
        //           dipositor_name,
        //           tr_date
        //         )
        //       );
        //       setSubmitted(true);
        //     }
        //   })
        //   .catch((error) => {
        //     console.log({ ...error });
        //   });
      } else {
        dispatch(
          transactionCreateAction(account_id, amt, dipositor_name, tr_date)
        );
        setSubmitted(true);
      }

      // setTimeout(() => {
      //   dispatch(transactionList());
      // }, 2000);
      // // setNotify({
      // //   isOpen: true,
      // //   message: 'Submitted Successfully!',
      // //   type: 'success',
      // // });
    }
  };

  const handleClose = () => {
    setOpenPopup(false);
    resetForm();
    setTimeout(() => {
      dispatch(transactionList());
    }, 2000);
  };

  const handleUploadClick = (event) => {
    console.log(event);
    var file = event.target.files[0];
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    console.log("file", file);

    reader.onloadend = function (e) {
      setMenuImagePreview([reader.result]);
    };
    console.log(url); // Would see a path?

    setPhoto(file);
  };

  const handleUploadClick2 = (event) => {
    console.log(event);
    var file = event.target.files[0];
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    console.log("file", file);

    reader.onloadend = function (e) {
      setMenuImagePreview2([reader.result]);
    };
    console.log(url); // Would see a path?

    setSignPhoto(file);
  };

  console.log("submitted", submitted);

  React.useEffect(() => {
    if (submitted === true) {
      setAccountId("");
      setValues({
        amt: "",
        dipositor_name: "",
      });
    }
  }, [submitted]);

  console.log(account_id);

  React.useEffect(() => {
    if (account_id) {
      var accountName = accounts
        .filter((item) => {
          return item.id === account_id;
        })
        .map((item) => `${item.premium}`);

      setValues({
        ...values,
        amt: accountName,
      });
    }
  }, [account_id]);

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={openPopup}>
      <Dialog_Title
        style={{ color: "whitesmoke" }}
        id="customized-dialog-title"
        onClose={handleClose}
      >
        Create Form
      </Dialog_Title>
      <Dialog_Content dividers>
        <InputForm
          checked={checked}
          submitted={submitted}
          account_id={account_id}
          setAccountId={setAccountId}
          setAccountType={setAccountType}
          setAccountGroup={setAccountGroup}
          account_type={account_type}
          account_group={account_group}
          setPhoto={setPhoto}
          menu_image_preview={menu_image_preview}
          setMenuImagePreview={setMenuImagePreview}
          photo={photo}
          setSignPhoto={setSignPhoto}
          menu_image_preview2={menu_image_preview2}
          setMenuImagePreview2={setMenuImagePreview2}
          sign_photo={sign_photo}
          setStatus={setStatus}
          account_status={account_status}
          value={values}
          handleInputChange={handleInputChange}
          handleUploadClick={handleUploadClick}
          handleUploadClick2={handleUploadClick2}
          error={errors}
          helperText={errors}
          accounts={accounts}
          handleChange1={handleChange1}
        />
      </Dialog_Content>
      <Dialog_Actions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Submit
        </Button>
      </Dialog_Actions>
    </Dialog>
  );
};

export default Popup;
