import React from "react";

import {
  Grid,
  Typography,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  FormControl,
  InputLabel,
  FormControlLabel,
  NativeSelect,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";

import Fab from "@mui/material/Fab";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 10,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  textField: {
    "&:hover": {
      backgroundColor: "whitesmoke",
    },
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    value,
    handleInputChange,
    error,
    helperText,
    setAccountType,
    setAccountGroup,
    account_type,
    account_group,
    setStatus,
    account_status,
    item,
    menu_image_preview,
    setMenuImagePreview,
    handleUploadClick,
    setPhoto,
    menu_image_preview2,
    setMenuImagePreview2,
    handleUploadClick2,
    setSignPhoto,
  } = props;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const accountGroupSelect = (type) => {
    if (type === "owners capital") {
      setAccountGroup("A");
    } else if (type === "bank loan") {
      setAccountGroup("L");
    } else if (type === "supplier") {
      setAccountGroup("L");
    } else if (type === "customer") {
      setAccountGroup("A");
    } else if (type === "fixed asset") {
      setAccountGroup("A");
    } else if (type === "cash") {
      setAccountGroup("A");
    } else if (type === "bank") {
      setAccountGroup("A");
    } else if (type === "sales") {
      setAccountGroup("I");
    } else if (type === "purchase") {
      setAccountGroup("E");
    } else {
      setAccountGroup("E");
    }
  };

  console.log("edit data", item);

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              name="account_name"
              variant="outlined"
              required
              fullWidth
              id="account_name"
              label="Account Name"
              autoFocus
              size="small"
              className={classes.textField}
              defaultValue={item ? item.account_name : null}
              error={error.account_name}
              helperText={helperText.account_name}
              onChange={handleInputChange}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="account_no"
              variant="outlined"
              required
              fullWidth
              id="account_no"
              label="Account No."
              autoFocus
              size="small"
              className={classes.textField}
              defaultValue={item ? item.account_no : null}
              error={error.account_no}
              helperText={helperText.account_no}
              onChange={handleInputChange}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="father_name"
              variant="outlined"
              fullWidth
              id="father_name"
              label="Father Name"
              autoFocus
              size="small"
              className={classes.textField}
              defaultValue={item ? item.father_name : null}
              error={error.father_name}
              helperText={helperText.father_name}
              onChange={handleInputChange}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="mother_name"
              variant="outlined"
              fullWidth
              id="mother_name"
              label="Mother Name"
              autoFocus
              size="small"
              className={classes.textField}
              defaultValue={item ? item.mother_name : null}
              error={error.mother_name}
              helperText={helperText.mother_name}
              onChange={handleInputChange}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="phone_no"
              variant="outlined"
              required
              fullWidth
              id="phone_no"
              label="Phone No."
              autoFocus
              size="small"
              className={classes.textField}
              defaultValue={item ? item.phone_no : null}
              error={error.phone_no}
              helperText={helperText.phone_no}
              onChange={handleInputChange}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="email"
              variant="outlined"
              fullWidth
              id="email"
              label="Email"
              autoFocus
              size="small"
              className={classes.textField}
              defaultValue={item ? item.email : null}
              error={error.email}
              helperText={helperText.email}
              onChange={handleInputChange}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="address"
              variant="outlined"
              fullWidth
              id="address"
              label="Address"
              autoFocus
              size="small"
              className={classes.textField}
              defaultValue={item ? item.address : null}
              error={error.address}
              helperText={helperText.address}
              onChange={handleInputChange}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="nid"
              variant="outlined"
              fullWidth
              id="nid"
              label="NID Number"
              autoFocus
              size="small"
              className={classes.textField}
              defaultValue={item ? item.nid : null}
              error={error.nid}
              helperText={helperText.nid}
              onChange={handleInputChange}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="nominee"
              variant="outlined"
              fullWidth
              id="nominee"
              label="Nominee Name"
              autoFocus
              size="small"
              className={classes.textField}
              defaultValue={item ? item.nominee : null}
              error={error.nominee}
              helperText={helperText.nominee}
              onChange={handleInputChange}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="nominee_nid"
              variant="outlined"
              fullWidth
              id="nominee_nid"
              label="Nominee NID"
              autoFocus
              size="small"
              className={classes.textField}
              defaultValue={item ? item.nominee_nid : null}
              error={error.nominee_nid}
              helperText={helperText.nominee_nid}
              onChange={handleInputChange}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="op_amt"
              variant="outlined"
              fullWidth
              id="op_amt"
              label="Opening Amount"
              autoFocus
              size="small"
              className={classes.textField}
              defaultValue={item ? item.op_amt : null}
              error={error.op_amt}
              helperText={helperText.op_amt}
              onChange={handleInputChange}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="premium"
              variant="outlined"
              fullWidth
              id="premium"
              label="Premium"
              autoFocus
              size="small"
              className={classes.textField}
              defaultValue={item ? item.premium : null}
              error={error.premium}
              helperText={helperText.premium}
              onChange={handleInputChange}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Account Status</FormLabel>
              <RadioGroup
                aria-label="status"
                InputLabelProps={{ style: { fontSize: 13 } }}
                name="status"
                defaultValue={item ? item.account_status : account_status}
                onChange={handleChange}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  value="Y"
                  control={<Radio size="small" />}
                  label={
                    <Typography style={{ fontSize: 14 }}>Active</Typography>
                  }
                />
                <FormControlLabel
                  value="I"
                  control={<Radio size="small" />}
                  label={
                    <Typography style={{ fontSize: 14 }}>Inactive</Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            {item ? (
              <TextField
                name="open_date"
                variant="outlined"
                required
                fullWidth
                id="open_date"
                label="Open Date"
                type="date"
                autoFocus
                size="small"
                defaultValue={item ? item.open_date : null}
                error={error.open_date}
                helperText={helperText.open_date}
                onChange={handleInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            ) : (
              ""
            )}
          </Grid>

          <Grid container xs={12} sm={6} justify="center" alignItems="center">
            <FormControl component="fieldset">
              <FormLabel component="div" style={{ marginLeft: 10 }}>
                Photo
              </FormLabel>
              {menu_image_preview !== "" ? (
                <div style={{ marginLeft: 10 }}>
                  <img width="100%" src={menu_image_preview} />

                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                      setPhoto("");
                      setMenuImagePreview("");
                    }}
                  >
                    <DeleteIcon fontSize="small" style={{ fill: "red" }} />
                  </IconButton>
                </div>
              ) : (
                <div style={{ marginLeft: 20 }}>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(event) => handleUploadClick(event)}
                  />
                  <label htmlFor="contained-button-file">
                    <Fab
                      component="span"
                      size="large"
                      style={{ height: 100, width: 100 }}
                    >
                      <AddPhotoAlternateIcon style={{ fontSize: 30 }} />
                    </Fab>
                  </label>
                </div>
              )}
            </FormControl>
          </Grid>

          {/* <Grid container xs={12} sm={6} justify="center" alignItems="center">
            <FormControl component="fieldset">
              <FormLabel component="legend">Sign Photo</FormLabel>
              {menu_image_preview2 !== "" ? (
                <div style={{ margin: 10 }}>
                  <img width="100%" src={menu_image_preview2} />

                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                      setSignPhoto("");
                      setMenuImagePreview2("");
                    }}
                  >
                    <DeleteIcon fontSize="small" style={{ fill: "red" }} />
                  </IconButton>
                </div>
              ) : (
                <>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(event) => handleUploadClick2(event)}
                  />
                  <label htmlFor="contained-button-file">
                    <Fab
                      component="span"
                      size="large"
                      style={{ height: 100, width: 100 }}
                    >
                      <AddPhotoAlternateIcon style={{ fontSize: 30 }} />
                    </Fab>
                  </label>
                </>
              )}
            </FormControl>
          </Grid> */}
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
